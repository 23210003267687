<template>
  <SuccessView
    headline="forgotPassword.success.headline"
    sub-headline="forgotPassword.success.subHeadline"
    :texts="['forgotPassword.success.text']"
    cta="forgotPassword.success.cta"
    image="@/assets/images/email-sent-success.svg"
    :is-loading="isLoading"
    :has-close="true"
    @click-cta="sendEmailAgin"
    @close="dialogStore.closeDialog"
  />
</template>
<script setup lang="ts">
import { useSendForgotPasswordSuccessView } from '~/components/form/sendForgotPasswordEmailForm/useSendForgotPasswordSuccessView';
import SuccessView from '~/components/formFields/components/successView/vdv/successView.vue';
import { useDialogStore } from '~/stores/useDialogStore';

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
});

const dialogStore = useDialogStore();

const { isLoading, sendEmailAgin } = useSendForgotPasswordSuccessView(
  props.email,
);
</script>
