<template>
  <Dialog>
    <SendForgotPassword
      v-if="!successEmail"
      @success="(email) => (successEmail = email)"
    />
    <SuccessView v-else :email="successEmail" />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import SendForgotPassword from '~/components/form/sendForgotPasswordEmailForm/vdv/sendForgotPasswordEmailForm.vue';
import SuccessView from '../components/successView/vdv/successView.vue';
import { useDialogStore } from '~/stores/useDialogStore';

const dialogStore = useDialogStore();
const successEmail = ref('');
dialogStore.setHeadline('forgotPassword.headline');
</script>
